import React from 'react';
import Loadable from 'react-loadable';
import IconPropList from '../partials/_Web-PropList';
import IconNotes from '../partials/_Web-Notes';
import {
  CodeSnippet,
  IconDataTableCode,
  PlatformTab,
  Section,
} from '../../../../components';
import V5Notice from '../../v5-notice/V5Notice';

const IconEffectArrow = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.IconEffectArrow;
    return <Component {...props} />;
  },
});

const snippet = require('raw-loader!../../../../data/snippets/icons-effect.example');

const WebTab = ({ data }) => {
  return (
    <PlatformTab>
      <V5Notice
        code
        componentName="Icon"
        storybookUrl="https://uniform-web.storybook.hudltools.com/?path=/docs/general-icon--docs"
      />
      <CodeSnippet
        scope={{ React: React, IconEffectArrow: IconEffectArrow }}
        code={snippet}
        platform="react"
        gitHubLink="icon"
      />

      <IconPropList />

      <IconNotes />

      <Section title="Icons">
        <IconDataTableCode data={data} platform="web" />
      </Section>
    </PlatformTab>
  );
};

export default WebTab;
